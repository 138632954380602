/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

/**
 * @author Daniel Mejia
 * @file contants.js
 * @description Constants that represent actions to execute
 */

export const LANGUAGE_STORE_NAME = 'language';
export const LOCALE_KEY = 'locale';
export const CHANGE_LOCALE = `${process.env.REACT_APP_PREFIX_NAME}/LanguageProviders/CHANGE_LOCALE`;
