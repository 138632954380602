/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Styles
import styles from './styles';

// Messages
import messages from './messages';

/**
 * Go to next component in the footer of screen.
 *
 * @author Daniel Mejia.
 * @class GoToNext
 */
function GoToNext({ disabled, onClick, classes }) {
  return (
    <div className={classes.footer}>
      <Button variant="contained" color="primary" disabled={disabled} onClick={onClick}>
        <FormattedMessage {...messages.nextButtonText} />
      </Button>
    </div>
  );
}

/**
 * Multimedia proptypes.
 *
 * @type { object }
 */
GoToNext.prototype = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default withStyles(styles)(GoToNext);
