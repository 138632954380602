/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: 'Software Organizacional Fácil e Inteligente'.
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: 'Superintendencia de Industria y Comercio'.
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { isEqual, isNil } from 'lodash';

// Components
import GoToNext from '../GoToNext';
import CustomLabel from '../CustomLabel';

// Styles
import styles from './styles';

/**
 * Select single answer view component.
 *
 * @author Daniel Mejia.
 * @class SelectSingleAnswer
 */
class SelectSingleAnswer extends React.PureComponent {
  /**
   * Component propTypes checker.
   *
   * @type { Object }
   */
  static propTypes = {
    question: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
  };

  /**
   * The component initial state.
   *
   * @type { Object }
   */
  initialState = { selectedOption: undefined };

  /**
   * Creates an instance of SelectSingleAnswer.
   *
   * @param { object } props The props for component.
   */
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  /**
   * Handles the go to next action.
   */
  handleGoToNext = () => {
    const { goToNext } = this.props;
    const { selectedOption } = this.state;
    goToNext(selectedOption);
    this.setState(this.initialState);
  }

  /**
   * React Lifecycle method, render all components to display in APP
   *
   * @return { object } The object to render.
   */
  render() {
    const { multimedia, question: { question, answers }, classes } = this.props;
    const { selectedOption } = this.state;
    const label = question.replace(/(\r\n|\n|\r)/gm, ' <br> ');

    return (
      <div className={classes.root}>
        <CustomLabel label={label} className={classes.questionTitle} variant="h5" gutterBottom />
        {multimedia}
        <div className={classes.answers}>
          {
            answers.map((answer, index) => (
              <Button
                key={`answer-${index.toString()}`}
                variant={isEqual(selectedOption, answer) ? 'contained' : 'outlined'}
                color={isEqual(selectedOption, answer) ? 'primary' : 'default'}
                classes={{ containedPrimary: classes.containedPrimary }}
                onClick={() => this.setState({ selectedOption: answer })}
              >
                {answer}
              </Button>
            ))
          }
        </div>
        <GoToNext disabled={isNil(selectedOption)} onClick={this.handleGoToNext} />
      </div>
    );
  }
}

export default withStyles(styles)(SelectSingleAnswer);
