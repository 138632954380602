/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Views
import WelcomeView from './views/Welcome/loadable';
import StartingUpLayout from './views/StartingUp/loadable';
import ContinueAssessment from './views/ContinueAssessment/loadable';
import Assessment from './views/Assessment/loadable';
import AssessmentSummary from './views/AssessmentSummary/loadable';
import AssessmentPreview from './views/AssessmentPreview';

// Components
import NotFoundPage from './components/NotFoundPage';

/**
 * Configure the application routing.
 *
 * @author Daniel Mejia
 * @file app.routing.jsx
 */
const Routes = () => (
  <Switch>
    <Route key="welcomeView" exact path="/" component={WelcomeView} />
    <Route key="startingUp" path="/startingUp" component={StartingUpLayout} />
    <Route key="continueAssessment" path="/continueAssessment" component={ContinueAssessment} />
    <Route key="assessment" path="/assessment" component={Assessment} />
    <Route key="assessmentSummary" path="/assessmentSummary" component={AssessmentSummary} />
    <Route key="assessmentPreview" path="/assessmentPreview" component={AssessmentPreview} />
    <Route key="notFoundPage" path="" component={NotFoundPage} />
  </Switch>
);

export default Routes;
