/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

/**
 * Constants that represent actions to execute
 *
 * @author Daniel Mejia
 * @file contants.js
 */

/**
 * Store name.
 */
export const STORE_NAME = 'cael/common/ReduxHelper';

/**
 * Document types constants.
 */
export const DOCUMENT_TYPES_STATE = 'documentTypes';
export const GET_DOCUMENT_TYPES_REQUEST = 'cael/common/ReduxHelper/GET_DOCUMENT_TYPES_REQUEST';

/**
 * Headquaters constants.
 */
export const HEADQUARTERS_STATE = 'headquarters';
export const GET_HEADQUARTERS_REQUEST = 'cael/common/ReduxHelper/GET_HEADQUARTERS_REQUEST';
