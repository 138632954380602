/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

/**
 * Css in js styles for {@link SelectSingleAnswer}
 *
 * @author Daniel Mejia
 * @file styles.js
 */

const styles = theme => ({
  questionTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  textField: {
    textAlign: 'center',
    marginBottom: '6em',
    '& > *': {
      width: '100%'
    }
  }
});

export default styles;
