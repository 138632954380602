/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: 'Software Organizacional Fácil e Inteligente'.
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: 'Superintendencia de Industria y Comercio'.
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Paper } from '@material-ui/core';
import { trim } from 'lodash';

// Components
import GoToNext from '../GoToNext';
import CustomLabel from '../CustomLabel';

// Messages
import messages from './messages';

// Styles
import styles from './styles';

/**
 * Writing question type componenet.
 *
 * @author Daniel Mejia.
 * @class Writing
 */
class Writing extends React.PureComponent {
  /**
   * Component propTypes checker.
   *
   * @type { Object }
   */
  static propTypes = {
    question: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
  };

  /**
   * The component initial state.
   *
   * @type { Object }
   */
  initialState = { response: '' };

  /**
   * Creates an instance of Writing.
   *
   * @param { object } props The props for component.
   */
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  /**
   * Handles the on change action for text field.
   */
  handleOnChange = ({ target: { value } }) => this.setState({ response: value });

  /**
   * Handles the go to next action.
   */
  handleGoToNext = () => {
    const { goToNext } = this.props;
    const { response } = this.state;
    goToNext(trim(response));
    this.setState(this.initialState);
  }

  /**
   * React Lifecycle method, render all components to display in APP
   *
   * @return { object } The object to render.
   */
  render() {
    const { intl: { formatMessage }, multimedia, question: { question }, classes } = this.props;
    const label = question.replace(/(\r\n|\n|\r)/gm, ' <br> ');
    const { response } = this.state;

    return (
      <div className={classes.root}>
        <CustomLabel label={label} className={classes.questionTitle} variant="h5" gutterBottom />
        {multimedia}
        <Paper className={classes.textField}>
          <TextField
            label={formatMessage(messages.inputLabel)}
            autoFocus
            multiline
            rows="10"
            rowsMax="10"
            value={response}
            onChange={this.handleOnChange}
            variant="outlined"
          />
        </Paper>
        <GoToNext disabled={!response} onClick={this.handleGoToNext} />
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(Writing));
