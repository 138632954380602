/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: 'Software Organizacional Fácil e Inteligente'.
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: 'Superintendencia de Industria y Comercio'.
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { isEqual } from 'lodash';

// Styles
import styles from './styles';

// Components
import AudioMultimedia from './AudioMultimedia';
import ImageMultimedia from './ImageMultimedia';

// Messages
import messages from './messages';

/**
 * Multimedia component for questions.
 *
 * @author Daniel Mejia.
 * @class Multimedia
 */
function Multimedia(props) {
  const { multimediaFile: { type, author }, classes } = props;

  if (!type) {
    return null;
  }

  return (
    <div className={classes.multimedia}>
      {
        isEqual(type, 'image') ? <ImageMultimedia {...props} messages={messages} /> : <AudioMultimedia {...props} />
      }
      {
        author && (
          <Typography className={classes.authorText} variant="caption" gutterBottom>
            <strong><FormattedMessage {...messages.authorLabel} /></strong>
            {author}
          </Typography>
        )
      }
    </div>
  );
}

/**
 * Multimedia proptypes.
 *
 * @type { object }
 */
Multimedia.propTypes = {
  multimediaFile: PropTypes.object
};

export default withStyles(styles)(Multimedia);
