/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

/**
 * Css in js styles for {@link SelectSingleAnswer}
 *
 * @author Daniel Mejia
 * @file styles.js
 */

const styles = theme => ({
  questionTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  containedPrimary: {
    backgroundColor: theme.palette.primary.answerColor,
    '&:hover': {
      backgroundColor: theme.palette.primary.answerColor,
      filter: 'brightness(120%)'
    }
  },
  answers: {
    textAlign: 'center',
    '& > *': {
      width: '100%',
      padding: '0.9em',
      marginBottom: '1.5em'
    }
  }
});

export default styles;
