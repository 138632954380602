/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import { defineMessages } from 'react-intl';
import { isFinite } from 'lodash';

/**
 * General app helper functions
 *
 * @author Daniel Mejia.
 * @file appHelper.js
 */

/**
 * General kinship options and messages.
 */
export const kinship = {
  options: ['FATHER', 'MOTHER', 'OTRO'],
  messages: defineMessages({
    formInputKinshipOptionFATHER: {
      id: 'CAEL.common.formInputKinshipOptionFATHER',
      defaultMessage: 'Padre'
    },
    formInputKinshipOptionMOTHER: {
      id: 'CAEL.common.formInputKinshipOptionMOTHER',
      defaultMessage: 'Madre'
    },
    formInputKinshipOptionOTRO: {
      id: 'CAEL.common.formInputKinshipOptionOTRO',
      defaultMessage: 'Otro'
    }
  })
};

/**
 * General kinshipdataTreament options and messages.
 */
export const kinshipData = {
  options: ['FATHER', 'MOTHER', 'ABUEL@', 'TI@', 'OTROS'],
  messages: defineMessages({
    formInputkinshipOptionFATHER: {
      id: 'CAEL.common.formInputkinshipOptionFATHER',
      defaultMessage: 'Padre'
    },
    formInputkinshipOptionMOTHER: {
      id: 'CAEL.common.formInputkinshipOptionMOTHER',
      defaultMessage: 'Madre'
    },
    formInputkinshipOptionABUELO: {
      id: 'CAEL.common.formInputkinshipOptionABUELO',
      defaultMessage: 'Abuel@'
    },
    formInputkinshipOptionTIO: {
      id: 'CAEL.common.formInputkinshipOptionTIO',
      defaultMessage: 'Ti@'
    },
    formInputkinshipOptionOTROS: {
      id: 'CAEL.common.formInputkinshipOptionOTROS',
      defaultMessage: 'Otros'
    }
  })
};

/**
 * Format a date only date.
 *
 * @param { string } date The date to format.
 *
 * @returns { string } The formated date.
 */
export const formatDate = date => date.slice(0, 10);

/**
 * Check if a values in integer.
 *
 * @param { any } value The value to check.
 *
 * @returns { boolean } true if the value is integer, false otherwise
 */
export const isInteger = value => isFinite(parseInt(value, 10));
