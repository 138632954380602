/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import { GenericService } from './genericService';

// Helpers
import { formatDate } from '../common/appHelper';

/**
 * Resource params.
 */
export const {
  DOCUMENT_PARAM,
  DOCUMENT_TYPE_PARAM,
  LEVEL_PARAM,
  BIRTHDATE_PARAM,
  PROGRAM_PARAM,
  HEADQUARTER_PARAM,
  ASSESSMENT_STATUS_PARAM,
  QUESTION_GROUP_PARAM,
  QUESTION_TYPE_PARAM,
  QUESTION_ID_PARAM,
  QUESTION_PREVIEW_RESOURCE
} = {
  DOCUMENT_PARAM: 'documentNumber',
  DOCUMENT_TYPE_PARAM: 'documentType',
  LEVEL_PARAM: 'level',
  BIRTHDATE_PARAM: 'birthdate',
  PROGRAM_PARAM: 'program',
  HEADQUARTER_PARAM: 'headquarter',
  ASSESSMENT_STATUS_PARAM: 'assessmentStatus',
  QUESTION_GROUP_PARAM: 'groupQuestionsId',
  QUESTION_TYPE_PARAM: 'groupTypeId',
  QUESTION_ID_PARAM: 'questionId',
  QUESTION_PREVIEW_RESOURCE: 'questionPreview'
};

export const assessmentStatuses = {
  IN_PROGRESS: 1,
  DONE: 2
};

/**
 * Assessment service {@link AssessmentService}
 *
 * @author Daniel Mejia
 * @class AssessmentService
 */
export class AssessmentService extends GenericService {
  /**
   * The service resource.
   *
   * @static
   * @type { string }
   */
  static RESOURCE = 'assessments';

  /**
   * Gets the parameter for create assessment request.
   *
   * @param { object } prospective The propspective.
   * @param { string } level The level selected for assessment.
   * @param { string } program The program calculated for assessment.
   *
   * @returns { object } The create assessment request params.
   */
  createAssessment(prospective, level, program) {
    const { documentNumber, documentType, birthdate, headquarter } = prospective;
    const url = AssessmentService.RESOURCE;
    const params = new URLSearchParams();
    params.append(DOCUMENT_PARAM, documentNumber);
    params.append(DOCUMENT_TYPE_PARAM, documentType);
    params.append(LEVEL_PARAM, level);
    params.append(PROGRAM_PARAM, program);
    params.append(HEADQUARTER_PARAM, headquarter);
    params.append(BIRTHDATE_PARAM, formatDate(birthdate));

    return this.create({}, { params, url });
  }

  /**
   * Gets the parameter for get assessment request.
   *
   * @param { object } prospective The propspective.
   *
   * @returns { object } The get assessment request params.
   */
  getAssessment(prospective) {
    const { documentNumber, documentType, assessmentStatus } = prospective;
    const url = AssessmentService.RESOURCE;
    const params = new URLSearchParams();
    params.append(DOCUMENT_PARAM, documentNumber);
    params.append(DOCUMENT_TYPE_PARAM, documentType);
    params.append(ASSESSMENT_STATUS_PARAM, assessmentStatus || assessmentStatuses.IN_PROGRESS);

    return this.get({ params, url });
  }

  /**
   * Gets the parameter to get an assessment question request.
   *
   * @param { object } prospective The propspective.
   *
   * @returns { object } The get assessment request params.
   */
  getAssessmentQuestion(assessmentId) {
    const url = `${AssessmentService.RESOURCE}/${assessmentId}`;
    return this.get({ url });
  }

  /**
   * Gets the parameters for validation question request.
   *
   * @param { number } assessmentId The assessment id to validate the question.
   * @param { object } requestQuestion The request question to validate.
   *
   * @returns { object } The validate question request params.
   */
  validateQuestion(assessmentId, requestQuestion) {
    const { PATCH: method } = this.requestMethods;
    const url = AssessmentService.RESOURCE;
    return this.update(assessmentId, requestQuestion, { method, url });
  }

  /**
   * Gets the question assessment preview.
   *
   * @param { number } questionGroupId The question group id of the question.
   * @param { number } questionTypeId The question type id of the question.
   * @param { number } questionId The question id.
   *
   * @returns { Promise<object> } The assessment question preview.
   */
  getQuestionPreview(questionGroupId, questionTypeId, questionId) {
    const url = `${AssessmentService.RESOURCE}/${QUESTION_PREVIEW_RESOURCE}`;
    const params = new URLSearchParams();
    params.append(QUESTION_GROUP_PARAM, questionGroupId);
    params.append(QUESTION_TYPE_PARAM, questionTypeId);
    params.append(QUESTION_ID_PARAM, questionId);

    return this.axiosInstance.get(url, { baseURL: this.baseURL, params });
  }
}

export default new AssessmentService();
