/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'connected-react-router/immutable';
import createSagaMiddleware from 'redux-saga';
import ReduxThunk from 'redux-thunk';
import { requestsPromiseMiddleware } from 'redux-saga-requests';

// App Reducers
import createReducers from './createReducers';

/**
 * @author React Boilerplate Team
 * @file configureStore.js
 * @description Create basic Store with dynamic reducers
 */

const sagaMiddleware = createSagaMiddleware();

/**
 * Check if the redux devtools is installed.
 *
 * @return { boolean } true if the devtools is installed, false otherwise.
 */
function isReduxDevToolsInstalled() {
  return process.env.REACT_APP_ENVIRONMENT_NAME === 'development'
    && typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

/**
 * Configure the store.
 *
 * @param { any } state The state.
 * @param { any } history The history.
 *
 * @return The store configured.
 */
export default function configureStore(state, history) {
  const middlewares = [
    requestsPromiseMiddleware({ auto: true }),
    sagaMiddleware,
    routerMiddleware(history), ReduxThunk
  ];
  const enhancers = [applyMiddleware(...middlewares)];
  const initialState = state || {};

  const composeEnhancers = isReduxDevToolsInstalled()
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false })
    : compose;

  const store = createStore(createReducers(), fromJS(initialState), composeEnhancers(...enhancers));
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {};
  store.injectedSagas = {};

  if (module.hot) {
    module.hot.accept('./createReducers', () => store.replaceReducer(createReducers(store.injectedReducers)));
  }

  return store;
}
