/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import { createSelector } from 'reselect';

// initial state
import { initialState } from './reducer';

// Constants
import { LANGUAGE_STORE_NAME, LOCALE_KEY } from './constants';

/**
 * The language provider state selectors.
 *
 * @author Daniel Mejia
 * @file selectors.js
 */

// Get reducer states
const selectLanguage = state => state.get(LANGUAGE_STORE_NAME, initialState);

// Get values from states
const makeSelectLocale = () => createSelector(selectLanguage, languageState => languageState.get(LOCALE_KEY));

export {
  selectLanguage,
  makeSelectLocale
};
