/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: 'Software Organizacional Fácil e Inteligente'.
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: 'Superintendencia de Industria y Comercio'.
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// Components
import LayoutDraggable from '../LayoutDraggable';
import CustomLabel from '../CustomLabel';

// Styles
import styles from './styles';

/**
 * Correct order draggable view component.
 *
 * @author Daniel Mejia.
 * @class CorrectOrderDraggable
 */
class CorrectOrderDraggable extends React.PureComponent {
  /**
   * Component propTypes checker.
   *
   * @type { Object }
   */
  static propTypes = {
    question: PropTypes.object,
    goToNext: PropTypes.func,
    classes: PropTypes.object
  };

  /**
   * React Lifecycle method, render all components to display in APP
   *
   * @return { object } The object to render.
   */
  render() {
    const { multimedia, question: { statement, question }, goToNext, classes } = this.props;
    const label = statement.replace(/(\r\n|\n|\r)/gm, ' <br> ');

    return (
      <div className={classes.root}>
        <CustomLabel label={label} className={classes.questionTitle} variant="h5" gutterBottom />
        {multimedia}
        <LayoutDraggable answersCount={question.length} options={[...question]} goToNext={goToNext} />
      </div>
    );
  }
}

export default withStyles(styles)(CorrectOrderDraggable);
