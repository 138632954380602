/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: 'Software Organizacional Fácil e Inteligente'.
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: 'Superintendencia de Industria y Comercio'.
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { isEqual } from 'lodash';
import SecureLS from 'secure-ls';

// Messages
import messages from '../messages';

// Secure storage
const secureStorage = new SecureLS({ encodingType: 'aes' });

/**
 * AudioMultimedia component for questions.
 *
 * @author Daniel Mejia.
 * @class AudioMultimedia
 */
export class AudioMultimedia extends React.Component {
  /**
   * The sound to play.
   *
   * @type { object }
   */
  sound = undefined;

  /**
   * Creates an instance of AudioMultimedia component.
   */
  constructor(props) {
    super(props);
    this.state = { repsDone: this.repsDone };
    this.handleOnPlayButton = this.handleOnPlayButton.bind(this);
  }

  /**
   * React callback called when the component was mounted in the browser.
   */
  componentDidMount() {
    this.initializeSound();
  }

  /**
   * React callback called when the component was updated.
   *
   * @param { object } prevProps The previous properties for the component.
   */
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.sound && this.sound.pause();
      this.initializeSound();
    }
  }

  /**
   * React callback called when the component was unmounted from the browser.
   */
  componentWillUnmount() {
    this.sound && this.sound.pause();
    secureStorage.remove(this.soundKey);
  }

  /**
   * Getter for reps done saved on local storage.
   *
   * @readonly
   * @returns { number } The repos done.
   */
  get repsDone() {
    return +secureStorage.get(this.soundKey);
  }

  /**
   * Set secure storage key
   *
   * @param { string } soundKey Id of local storage.
   * @param { number } repliesDone Number of replies from an audio.
   */
  setSecureStore(soundKey, repliesDone) {
    const { previewView } = this.props;
    if (previewView !== 'preview') {
      secureStorage.set(soundKey, repliesDone);
      this.setState({ repliesDone });
    }
  }

  /**
   * Initialize sound.
   */
  initializeSound() {
    const { questionKey, multimediaFile: { multimedia } } = this.props;
    this.sound = new Audio(multimedia);

    this.soundKey = questionKey;
    this.setSecureStore(this.soundKey, this.repsDone);
  }

  /**
   * Handler for play button.
   */
  handleOnPlayButton() {
    this.sound.play();
    this.sound.onended = () => this.forceUpdate();
    this.setSecureStore(this.soundKey, this.repsDone + 1);
  }

  /**
   * React callback called when the component is rendered in the browser.
   */
  render() {
    const { maxReps = 2, classes } = this.props;
    const isPlaying = this.sound && !this.sound.paused;

    return (
      <div>
        <Button
          className={classes.multimediaAudio}
          variant="contained"
          color="primary"
          disabled={this.repsDone >= maxReps || isPlaying}
          onClick={this.handleOnPlayButton}
        >
          <PlayArrowIcon />
        </Button>
        <Typography className={classes.authorText} variant="caption" gutterBottom>
          <FormattedMessage {...messages.maxReps} values={{ quantity: maxReps }} />
        </Typography>
      </div>
    );
  }
}

export default AudioMultimedia;
