/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import history from './utils/redux/utils/history';
import 'babel-polyfill';

// Utils
import { translationMessages } from './utils/i18n';
import configureStore from './utils/redux/configureStore';
import * as serviceWorker from './utils/serviceWorker';

// Styles
import './index.scss';
import { appTheme } from './styles';

// Containers
import App from './containers/App';
import LanguageProvider from './containers/LanguageProvider';

/**
 * Initialize APP.
 *
 * @author Daniel Mejia
 * @file index.jsx
 */

const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root');

/**
 * Initialize the application with messages.
 *
 * @param { object } messages The messages object.
 */
const renderApp = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <MuiThemeProvider theme={appTheme}>
          <ThemeProvider theme={appTheme}>
            <ConnectedRouter history={history}>
              <App />
            </ConnectedRouter>
          </ThemeProvider>
        </MuiThemeProvider>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  module.hot.accept(['./utils/i18n', './containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    renderApp(translationMessages);
  });
}

if (window.Intl) {
  renderApp(translationMessages);
} else {
  new Promise(resolve => resolve(import('intl')))
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js'), import('intl/locale-data/jsonp/es.js')]))
    .then(() => renderApp(translationMessages))
    .catch((err) => {
      throw err;
    });
}

serviceWorker.unregister();
