/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import invariant from 'invariant';
import { isEmpty, isFunction, isString, conformsTo } from 'lodash';

// Util
import checkStore from '../utils/checkStore';

/**
 * Core inject saga.
 *
 * @author React Boilerplate Team
 * @file sagaInjector.js
 */

// Constants
const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
const DAEMON = '@@saga-injector/daemon';
const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
const allowedModes = [RESTART_ON_REMOUNT, DAEMON, ONCE_TILL_UNMOUNT];
const errorMessage = '(app/utils...) injectSaga: Expected `key` to be a non empty string';
/**
 * Validate if key variable is valid.
 *
 * @param { string } key
 */
const checkKey = key => invariant(isString(key) && !isEmpty(key), errorMessage);

/**
 * Validate if descriptor is valid.
 *
 * @param { object } descriptor
 */
const checkDescriptor = (descriptor) => {
  const shape = {
    saga: isFunction,
    mode: mode => isString(mode) && allowedModes.includes(mode)
  };
  invariant(conformsTo(descriptor, shape), '(app/utils...) injectSaga: Expected a valid saga descriptor');
};

/**
 * Validate  if saga is valid.
 *
 * @param { boolean } hasSaga
 * @param { string } mode
 */
const checkHasSaga = (hasSaga, mode) => (!hasSaga
  || (hasSaga && mode !== DAEMON && mode !== ONCE_TILL_UNMOUNT));

/**
 * Return object descriptor with mode change.
 *
 * @param { object } descriptor
 */
const getDescriptor = descriptor => ({
  ...descriptor,
  mode: descriptor.mode || RESTART_ON_REMOUNT
});


/**
 * Add Saga to injectables.
 *
 * @param { function } store
 * @param { boolean } isValid
 */
export function injectSagaFactory(store, isValid) {
  return function injectSaga(key, des, args) {
    const descriptor = des || {};
    if (!isValid) {
      checkStore(store);
    }

    const newDescriptor = getDescriptor(descriptor);
    const { saga, mode } = newDescriptor;

    checkKey(key);
    checkDescriptor(newDescriptor);

    let hasSaga = Reflect.has(store.injectedSagas, key);

    if (process.env.NODE_ENV !== 'production') {
      const oldDescriptor = store.injectedSagas[key];
      // enable hot reloading of daemon and once-till-unmount sagas
      if (hasSaga && oldDescriptor.saga !== saga) {
        oldDescriptor.task.cancel();
        hasSaga = false;
      }
    }

    if (checkHasSaga(hasSaga, mode)) {
      /* eslint-disable no-param-reassign */
      store.injectedSagas[key] = {
        ...newDescriptor,
        task: store.runSaga(saga, args)
      };
      /* eslint-enable no-param-reassign */
    }
  };
}

/**
 * Remove Saga from injectables.
 *
 * @param { function } store The store.
 * @param { boolean } isValid Value to identify if is valid.
 */
export function ejectSagaFactory(store, isValid) {
  return function ejectSaga(key) {
    if (!isValid) {
      checkStore(store);
    }

    checkKey(key);

    if (Reflect.has(store.injectedSagas, key)) {
      const descriptor = store.injectedSagas[key];
      if (descriptor.mode && descriptor.mode !== DAEMON) {
        descriptor.task.cancel();
        // Clean up in production; in development we need `descriptor.saga` for hot reloading
        if (process.env.NODE_ENV === 'production') {
          // Need some value to be able to detect `ONCE_TILL_UNMOUNT` sagas in `injectSaga`
          store.injectedSagas[key] = 'done'; // eslint-disable-line no-param-reassign
        }
      }
    }
  };
}

/**
 * Return functions inject and eject.
 *
 * @param { function } store The store.
 */
export default function getInjectors(store) {
  checkStore(store);

  return {
    injectSaga: injectSagaFactory(store, true),
    ejectSaga: ejectSagaFactory(store, true)
  };
}
