/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Messages
import messages from './messages';

// Styles
import './style.scss';

/**
 * Not found page component.
 *
 * @author Daniel Mejia
 * @file index.jsx
 */

/**
 * Creates the NotFoundPage component.
 *
 * @return { object } The NotFoundPage component.
 */
export default function () {
  return (
    <h1>
      <FormattedMessage {...messages.pageMessage} />
    </h1>
  );
}
