/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

/**
 * Css in js styles for {@link Multimedia}
 *
 * @author Daniel Mejia
 * @file styles.js
 */

const styles = theme => ({
  multimedia: {
    marginTop: '2em',
    marginBottom: '2em',
    textAlign: 'center'
  },
  imageLoader: {
    padding: '2em',
    '& .loader-text': {
      paddingBottom: '0.2em',
      color: theme.palette.primary.main
    }
  },
  multimediaImage: {
    width: '40%',
    height: 'auto',
    [theme.breakpoints.only('md')]: {
      width: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  multimediaAudio: {
    marginBottom: '0.4em'
  },
  authorText: {
    textAlign: 'center',
    color: theme.palette.primary.questionTextColor
  }
});

export default styles;
