/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import { defineMessages } from 'react-intl';

/**
 * Messages for {@link Writing}
 *
 * @author Daniel Mejia
 * @file messages.js
 */
export default defineMessages({
  inputLabel: {
    id: 'CAEL.components.Questions.Writing.inputLabel',
    defaultMessage: 'Escribe aquí...'
  }
});
