/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IntlProvider } from 'react-intl';

// Selectors
import { makeSelectLocale } from './selectors';
import { DEFAULT_LOCALE } from '../../utils/i18n';

/**
 * Languaje Provider componenet.
 *
 * @author Daniel Mejia
 * @file index.js
 */
export class LanguageProvider extends React.PureComponent {
  render() {
    const { locale, messages, children } = this.props;
    return (
      <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} key={locale} messages={messages[locale]}>
        {React.Children.only(children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired
};

const mapStateToProps = createSelector(makeSelectLocale(), locale => ({ locale }));
export default connect(mapStateToProps)(LanguageProvider);
