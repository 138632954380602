/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

/**
 * Language provider reducer to manage translates in the app
 *
 * @author Daniel Mejia
 * @file reducer.js
 */

// Dependencies
import { fromJS } from 'immutable';
import { createReducer } from 'redux-starter-kit';

// Constants
import { CHANGE_LOCALE, LOCALE_KEY } from './constants';
import { DEFAULT_LOCALE } from '../../utils/i18n';

// Initial state
export const initialState = fromJS({ locale: DEFAULT_LOCALE });

/**
 * Creates the language provider reducer.
 */
const languageProviderReducer = createReducer(initialState, {
  [CHANGE_LOCALE]: (state, action) => state.set(LOCALE_KEY, action.payload)
});

export default languageProviderReducer;
