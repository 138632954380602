/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { isEqual } from 'lodash';

// Utils
import { getDefaultState, createRequestReducer } from '../reducerHelper';

// Constants
import { STORE_NAME,
  DOCUMENT_TYPES_STATE,
  HEADQUARTERS_STATE,
  GET_DOCUMENT_TYPES_REQUEST,
  GET_HEADQUARTERS_REQUEST } from './constants';
import { RESET_STORE } from '../../containers/App/redux/constants';

/**
 * Starting up reducer.
 *
 * @author Daniel Mejia
 * @file reduxHelperReducer.js
 */

/**
 * Initial state
 *
 * @type { Inmutable.Map }
 */
export const initialState = fromJS({
  [DOCUMENT_TYPES_STATE]: getDefaultState({ multiple: true }),
  [HEADQUARTERS_STATE]: getDefaultState({ multiple: true })
});

/**
 * Request reducer for document types state.
 */
const documentTypesReducer = createRequestReducer(GET_DOCUMENT_TYPES_REQUEST, { multiple: true });

/**
 * Request reducer for headquarters state.
 */
const headquartersReducer = createRequestReducer(GET_HEADQUARTERS_REQUEST, { multiple: true });

/**
 * Starting up root reducer definition.
 */
export const reducerDefinition = {
  [DOCUMENT_TYPES_STATE]: documentTypesReducer,
  [HEADQUARTERS_STATE]: headquartersReducer
};

/**
 * Reducer for each resource.
 */
const reducers = combineReducers(reducerDefinition);

/**
 * Root helper reducer.
 *
 * @param { Inmutable.Map } [state=initialState] The state for reducer.
 * @param { object } [action={}] The action to be executed in the reducer.
 *
 * @returns { Inmutable.Map } The new reducer state.
 */
function rootReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  if (isEqual(type, RESET_STORE) && isEqual(payload, STORE_NAME)) {
    return initialState;
  }

  return reducers(state, action);
}

export default rootReducer;
