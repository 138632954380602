/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

/**
 * Styles form {@link GoToNext}
 *
 * @author Daniel Mejia
 * @file styles.js
 */

const styles = theme => ({
  footer: {
    /* position: 'fixed', */
    bottom: '0',
    left: '0',
    width: '100%',
    paddingLeft: '0',
    paddingRight: '0',
    paddingTop: '1.5em',
    paddingBottom: '1.5em',
    /* backgroundColor: '#F7F7F7', */
    textAlign: 'center',
    '& > button': {
      marginLeft: '10%',
      marginRight: '10%',
      width: '50%'
    }
  }
});

export default styles;
