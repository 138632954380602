/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import Loadable from 'react-loadable';

// Components
import Loading from '../../components/Loading';

/**
 * Asynchronously loads the component for Starting up view.
 *
 * @author Daniel Mejia
 * @file loadable.js
 */
export default Loadable({
  loader: () => import('./index'),
  loading: Loading
});
