/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Depedencies
import axios from 'axios';

/**
 * General axios instance for make request.
 */
export const axiosInstance = axios.create();

/**
 * Generic service for basic http request.
 *
 * @author Daniel Mejia
 * @class GenericService
 */
export class GenericService {
  /**
   * The axios instance to make http request.
   *
   * @type { AxiosInstance }
   */
  axiosInstance;

  /**
   * The default resource to get values.
   *
   * @type { String }
   */
  resource;

  /**
   * The default request methods available.
   *
   * @type { String }
   */
  requestMethods = { POST: 'post', PUT: 'put', PATCH: 'patch' };

  /**
   * Creates an instance of GenericService.
   *
   * @param { String } resource To manipulates the operations.
   * @param { String } [baseURL=process.env.REACT_APP_API] The base url, by default REACT_APP_API.
   */
  constructor(resource, baseURL = process.env.REACT_APP_API) {
    this.baseURL = baseURL;
    this.resource = resource;
    this.axiosInstance = axiosInstance;
  }

  /**
   * Builds the request configuration based on params
   *
   * @param { Object } requestConfig Request params.
   *
   * @return { Object } The request configuration.
   */
  buildRequest(requestConfig) {
    return {
      request: {
        baseURL: this.baseURL,
        ...requestConfig
      }
    };
  }

  /**
   * Gets the params for a 'GET' request.
   *
   * @param { Object } [params=this.defaultParams]
   *
   * @return { Object } The configuration for 'GET' request.
   */
  get({ params, url = this.resource } = this.defaultParams) {
    return this.buildRequest({ url, params });
  }

  /**
   * Gets the params for a 'GET' request finding by id.
   *
   * @param { Number } id The id to find by.
   * @param { String } [requestUrl=this.resource]
   *
   * @return { Object } The configuration for 'GET' request by id.
   */
  findById(id, { params, url = this.resource } = this.defaultParams) {
    const requestUrl = `${url}/${id}`;
    return this.buildRequest({ url: requestUrl, params });
  }

  /**
   * Gets the params for a 'POST' request to create an instance.
   *
   * @param { Object } [data={}] The instance to create.
   * @param { String } [url=this.resource] Optional request url to override default.
   *
   * @return { Object } The configuration for 'POST' request to create an instance.
   */
  create(data, { params, url = this.resource } = this.defaultParams) {
    const { POST: method } = this.requestMethods;
    return this.buildRequest({ url, params, data, method });
  }

  /**
   * Gets the params for a 'PUT' request to update and instance.
   *
   * @param { Number } id The id to update by.
   * @param { Object } data The instance to update.
   * @param { String } [requestUrl=this.resource] Optional request url to override default.
   *
   * @return { Object } The configuration for 'PUT' request to update an instance.
   */
  update(id, data, { params, url = this.resource, method = this.requestMethods.PUT } = this.defaultParams) {
    const requestUrl = `${url}/${id}`;
    return this.buildRequest({ url: requestUrl, params, data, method });
  }

  /**
   * Getter for default request params.
   *
   * @readonly
   * @returns { object } The default request params.
   */
  get defaultParams() {
    return {
      url: this.resource
    };
  }
}

export default (resource, baseURL) => new GenericService(resource, baseURL);
