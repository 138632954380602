/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import { fromJS } from 'immutable';
import reduceReducers from 'reduce-reducers';
import { isEqual } from 'lodash';

// Constants
import { FETCHING_DATA, FETCHING_ERROR } from './constants';

/**
 * Global reducer to manage common data in the application
 *
 * @author Daniel Mejia
 * @file appReducer.js
 */

/**
 * The initial state of the App
 */
export const initialState = fromJS({
  [FETCHING_DATA]: false,
  [FETCHING_ERROR]: false
});

/**
 * The loading reducer to controls the loading state in feching date.
 *
 * @param { Inmutable.Map } [state=initialState] The state to manage the reducer.
 * @param { Object } [action={}] The action to execute the reducer.
 *
 * @return { Inmutable.Map } The new state after execute the action.
 */
const loadingReducer = (state = initialState, { type } = {}) => {
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE|ERROR)/.exec(type);
  if (matches) {
    const requestState = matches[2];
    return state
      .set(FETCHING_DATA, isEqual(requestState, 'REQUEST'))
      .set(FETCHING_ERROR, isEqual(requestState, 'FAILURE') || isEqual(requestState, 'ERROR'));
  }

  return state;
};

export default reduceReducers(loadingReducer);
