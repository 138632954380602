/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Depedencies
import { fromJS } from 'immutable';
import { createReducer } from 'redux-starter-kit';
import { success, error, abort } from 'redux-saga-requests';

/**
 * Gets the default state for request reducer.
 *
 * @param { Object } params The params to get the state.
 *
 * @return { Object } The default state param.
 */
export const getDefaultState = ({ multiple } = {}) => ({
  data: multiple ? [] : null,
  fetching: false,
  error: false
});

/**
 * Gets the next state for request action.
 *
 * @param { String } requestState The request state.
 *
 * @return { Inmutable.Map } The inmutable next state.
 */
export const getRequestNextState = requestState => fromJS({ ...requestState, fetching: true });

/**
 * Gets the next state for request success action.
 *
 * @param { String } requestState The request state.
 *
 * @return { Inmutable.Map } The inmutable next state.
 */
export const getRequestSuccessNextState = (requestState, multiple = false) => ({ payload: { data } }) => fromJS({
  ...requestState,
  data: multiple ? [...data] : { ...data }
});

/**
 * Gets the next state for request error action.
 *
 * @param { String } requestState The request state.
 *
 * @return { Inmutable.Map } The inmutable next state.
 */
export const getRequestErrorNextState = requestState => fromJS({ ...requestState, error: true });

/**
 * Gets the next state for request abortaction.
 *
 * @param { String } requestState The request state.
 *
 * @return { Inmutable.Map } The inmutable next state.
 */
export const getRequestAbortNextState = requestState => fromJS({ ...requestState, fetching: false });

/**
 * Helper reducer to create a new reducer for request reducer.
 *
 * @param { string } resource The resource to manage for reducer.
 * @param { object } params The params to configure the reducer.
 *
 * @return { Reducer } The reducer to manages the resource.
 */
export const createRequestReducer = (resource, { containerState, stateKey, multiple } = {}) => {
  const requestState = getDefaultState({ multiple });
  const requestNextState = getRequestNextState(requestState);
  const requestSuccessNextState = getRequestSuccessNextState(requestState, multiple);
  const requestErrorNextState = getRequestErrorNextState(requestState);
  const requestAbortNextState = getRequestAbortNextState(requestState);

  if (containerState) {
    return createReducer(containerState, {
      [resource]: state => state.set(stateKey, requestNextState),
      [success(resource)]: (state, action) => state.set(stateKey, requestSuccessNextState(action)),
      [error(resource)]: state => state.set(stateKey, requestErrorNextState),
      [abort(resource)]: state => state.set(stateKey, requestAbortNextState)
    });
  }

  return createReducer(fromJS(requestState), {
    [resource]: () => requestNextState,
    [success(resource)]: (_state, action) => requestSuccessNextState(action),
    [error(resource)]: () => requestErrorNextState,
    [abort(resource)]: () => requestAbortNextState
  });
};
