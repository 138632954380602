/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Styles
import styles from './styles';

// Assets
/* import isotype from '../../../assets/isotype.png'; */

/**
 * Layout component for questions.
 *
 * @author Daniel Mejia.
 * @class Layout
 */
function Layout({ title, children, classes }) {
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.title} variant="h4" gutterBottom>
          {title}
        </Typography>
        {/* <img src={isotype} alt="application logo" className={classes.logo} /> */}
      </div>
      <div className={classes.pageContent}>
        {children}
      </div>
    </div>
  );
}

export default withStyles(styles)(Layout);
