/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

/**
 * Css in js styles for {@link LayoutDraggable}
 *
 * @author Daniel Mejia
 * @file styles.js
 */

const styles = theme => ({
  small: {
    width: '6em'
  },
  medium: {
    width: '12em'
  },
  large: {
    width: '20em'
  },
  options: {
    marginTop: '2em',
    minHeight: '12em',
    border: '0.1em solid',
    borderRadius: '1em',
    borderColor: '#EBEBEB',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& .container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      flexDirection: 'row',
      minHeight: '12em',
      padding: '0.5em',
      width: '100%'
    },
    '& .option': {
      padding: '0.5em 1.5em',
      margin: '0.5em',
      borderRadius: '0.7em'
    }
  },
  column: {
    flexDirection: 'column !important'
  },
  img: {
    margin: 'auto',
    textAlign: 'center'
  },
  logo: {
    width: '25%',
    height: '14.5em !important',
    marginTop: '4em',
    [theme.breakpoints.down('xs')]: {
      marginTop: '1em',
      width: '90%',
      height: '10.5em !important',
      marginRight: '-3em !important'
    }
  }
});

export default styles;
