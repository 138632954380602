/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: 'Software Organizacional Fácil e Inteligente'.
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: 'Superintendencia de Industria y Comercio'.
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// Styles
import styles from './styles';

/**
 * Flexible label to allow break lines and children items.
 *
 * @author Daniel Mejia.
 * @class CustomLabel
 */
function CustomLabel(props) {
  const { label, children, variant, classes, asColumn } = props;
  const list = typeof label === 'string'
    ? label.split(' ').map((item, index) => [item, index])
    : label;

  return (
    <div className={`${classes.container} ${asColumn && classes.column}`} style={{ order: -1 }}>
      {
        list && list.map(([labelItem, order], index) => {
          const itemKey = `label-${labelItem}-${index.toString()}`;
          if (labelItem === '<br>') {
            return <div key={itemKey} style={{ order }} className={classes.breakLine} />;
          }

          return (
            <Typography
              key={itemKey}
              className="label"
              style={{ order }}
              variant={variant}
              gutterBottom
            >
              {labelItem}
            </Typography>
          );
        })
      }
      {children}
    </div>
  );
}

export default withStyles(styles)(CustomLabel);
