/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import { addLocaleData } from 'react-intl';
import esLocaleData from 'react-intl/locale-data/es';
import enLocaleData from 'react-intl/locale-data/en';

/**
 * This will setup the i18n languages files and locale data for your app.
 *
 * @author React Boilerplate Team
 * @file index.js
 *
 * IMPORTANT: This file is used by the internal build
 * script `extract-intl`, and must use CommonJS module syntax
 * You CANNOT use import/export in this file.
 */

// translations
const esTranslationMessages = require('./translations/es.json');
const enTranslationMessages = require('./translations/en.json');

// Constants
const DEFAULT_LOCALE = 'es';

// prettier-ignore
const appLocales = ['es', 'en'];

addLocaleData(esLocaleData, enLocaleData);

/**
 * Format the translation messages.
 *
 * @param { any } locale The locale information.
 * @param { object } messages The messages object.
 *
 * @return { object } The formated messages translated.
 */
const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages = locale === DEFAULT_LOCALE
    ? {}
    : formatTranslationMessages(DEFAULT_LOCALE, esTranslationMessages);

  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage = (!messages[key] && locale !== DEFAULT_LOCALE)
      ? defaultFormattedMessages[key]
      : messages[key];

    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

// Translations
const translationMessages = {
  es: formatTranslationMessages('es', esTranslationMessages),
  en: formatTranslationMessages('en', enTranslationMessages)
};

export {
  appLocales,
  formatTranslationMessages,
  translationMessages,
  DEFAULT_LOCALE
};
