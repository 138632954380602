/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import ReduxBlockUi from 'react-block-ui/redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';

// Application routes
import Routes from '../../routes';

// Redux
import { isFetchingData } from './redux/selectors';

// Componenets
import Loader from '../../components/Loading';

// Styles
import 'react-block-ui/style.css';

// Utils
import injectSaga from '../../utils/redux/injectables/injectSaga';

// Redux
import saga from './redux/saga';
import { STORE_NAME } from './redux/constants';

/**
 * App View Container.
 *
 * @author Daniel Mejia
 * @file index.jsx
 */
function App(props) {
  const appName = `${process.env.REACT_APP_PREFIX_NAME}`;
  return (
    <div id="App">
      <Helmet titleTemplate={`%s - ${appName}`} defaultTitle={appName} />
      <ReduxBlockUi className="blocker-ui" tag="div" blocking={props.isFetchingData} loader={Loader}>
        <Routes />
      </ReduxBlockUi>
    </div>
  );
}

/**
 * Map the state props to components props.
 */
const mapStateToProps = createStructuredSelector({ isFetchingData });

/**
 * Inject reducer to component
 */
const withConnect = connect(mapStateToProps);

/**
 * Inject reducer to component
 */
const withSaga = injectSaga({ key: STORE_NAME, saga });

export default compose(withSaga, withRouter, withConnect)(App);
