/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import { createSelector } from 'reselect';

// Reducer
import { initialState } from './appReducer';

// Constants
import { STORE_NAME, FETCHING_DATA, FETCHING_ERROR } from './constants';

/**
 * Get store values to App.
 *
 * @author Daniel Mejia
 * @file selectors.js
 */

/**
 * Gets app state.
 *
 * @param { any } state The state.
 * @return { any } The app state.
 */
const globalAppStore = state => state.get(STORE_NAME, initialState);

/**
 * Get the is shown loading state.
 *
 * @return { import('reselect').OutputSelector } The selector created.
 */
const isFetchingData = createSelector(globalAppStore, state => state.get(FETCHING_DATA));

/**
 * There are a error fetching data.
 *
 * @return { import('reselect').OutputSelector } The selector created.
 */
const isFetchingError = createSelector(globalAppStore, state => state.get(FETCHING_ERROR));

export {
  globalAppStore,
  isFetchingData,
  isFetchingError
};
