/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { Typography } from '@material-ui/core';

// Components
import QuestionLayout from '../../components/Questions/Layout';
import Stepper from '../../components/Questions/Stepper';
import Loading from '../../components/Loading';

// Services
import assessmentService from '../../services/assessmentService';

// Styles
import styles from './styles';

// Messages
import messages from './messages';

/**
 * Assessment Preview component
 *
 * @author Daniel Mejia
 * @class AssessmentPreview
 */
export class AssessmentPreview extends React.PureComponent {
  /**
   * Creates an instance of AssessmentPreview.
   *
   * @param { object } props The component properties.
   */
  constructor(props) {
    super(props);
    this.state = { loading: true, error: false, assessmentQuestion: undefined };
  }

  /**
   * Callback execute when the component was mounted in the browser.
   */
  componentDidMount() {
    const { history, location: { search } } = this.props;
    const { questionGroupId, questionTypeId, questionId } = queryString.parse(search);
    if (questionGroupId && questionTypeId && questionId) {
      this.loadAssessmentQuestion(questionGroupId, questionTypeId, questionId);
    } else {
      history.replace('/');
    }
  }

  /**
   * Loads the assessment question preview.
   */
  async loadAssessmentQuestion(questionGroupId, questionTypeId, questionId) {
    try {
      const { data } = await assessmentService.getQuestionPreview(questionGroupId, questionTypeId, questionId);
      this.setState({ assessmentQuestion: data, loading: false });
    } catch (error) {
      this.setState({ error: error.response, loading: false });
    }
  }

  /**
   * Callback execute to render the view in the browser.
   *
   * @returns { Component } The view to render in the browser.
   */
  render() {
    const { classes } = this.props;
    const { loading, error, assessmentQuestion } = this.state;
    const previewView = 'preview';

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <div className={classes.centered}>
          <Typography variant="h5">
            <FormattedMessage {...messages.errorText} />
          </Typography>
        </div>
      );
    }

    return (
      <QuestionLayout title={assessmentQuestion.title}>
        <Stepper assessmentQuestion={assessmentQuestion} goToNext={() => {}} previewView={previewView} />
      </QuestionLayout>
    );
  }
}

export default withStyles(styles)(AssessmentPreview);
