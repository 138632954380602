/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import { createMuiTheme } from '@material-ui/core/styles';

/**
 * Global styles for the application.
 *
 * @author Daniel Mejia
 * @file styles.js
 */

/**
 * Application colors.
 */
export const primaryColor = '#a6ce39';
export const answerColor = '#00b1a2';
export const questionTextColor = '#484848';

/**
 * Application theme for Material UI.
 */
export const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
      answerColor,
      questionTextColor
    }
  },
  typography: {
    useNextVariants: true
  }
});
