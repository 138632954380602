/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: 'Software Organizacional Fácil e Inteligente'.
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: 'Superintendencia de Industria y Comercio'.
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Paper } from '@material-ui/core';
import { isEmpty, startsWith, isEqual, last, isNil, head } from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';

// Components
import Droppable from '../../DragAndDrog/Droppable';
import Draggable from '../../DragAndDrog/Draggable';
import GoToNext from '../GoToNext';
import CustomLabel from '../CustomLabel';

// Styles
import styles from './styles';

// Assets
// eslint-disable-next-line import/no-unresolved
import isotype from '../../../assets/isotype.png';


/**
 * Layout for draggable questions.
 *
 * @author Daniel Mejia.
 * @class LayoutDraggable
 */
class LayoutDraggable extends React.Component {
  /**
   * Component propTypes checker.
   *
   * @type { Object }
   */
  static propTypes = {
    classes: PropTypes.object,
    answersCount: PropTypes.number,
    options: PropTypes.array,
    boxSize: PropTypes.string,
    goToNext: PropTypes.func
  };

  /**
   * Component default props.
   *
   * @type { Object }
   */
  static defaultProps = {
    boxSize: 'small'
  }

  /**
   * Creates an instance of LayoutDraggable.
   *
   * @param { object } props The props for component.
   */
  constructor(props) {
    super(props);
    this.state = this.defaultState;
  }

  /**
   * React lifecycle executed after the component is updated.
   *
   * @param { object } prevProps The previous props for component.
   */
  componentDidUpdate(prevProps) {
    this.setDefaultState(prevProps);
  }

  /**
   * Set default state to component base on prevOptions.
   *
   * @param { object } prevOptions The previous options to compare with current options.
   */
  setDefaultState(prevProps) {
    const { labels, options } = this.props;
    const labelsEquals = isEqual(labels, prevProps.labels);
    const optionsEqual = isEqual(options, prevProps.options);
    const sameProps = labelsEquals && optionsEqual;

    if (!sameProps) {
      this.setState(this.defaultState);
    }
  }

  /**
   * Sets a selected option to answers.
   *
   * @param { string } selectedOption The option selected to set.
   * @param { number } [answerIndex=this.state.answers.indexOf(undefined)] The option index.
   */
  setAnswer(selectedOption, optionIndex, answerIndex = this.state.answers.indexOf(undefined)) {
    if (answerIndex >= 0) {
      const { answers, options } = this.state;
      const newOptions = options.filter((option, index) => !(option === selectedOption && index === optionIndex));
      answers[answerIndex] = selectedOption;
      this.setState({ answers, options: newOptions });
    }
  }

  /**
   * Sets a selected answer to options.
   *
   * @param { string } answer The answer to set in option.
   * @param { number } answerIndex The answer index.
   */
  setOption(answer, answerIndex) {
    const { answers, options } = this.state;
    options.push(answer);
    answers[answerIndex] = undefined;
    this.setState({ answers, options });
  }

  /**
   * Validates if the response for question is invalid.
   *
   * @readonly
   * @returns { boolean } true if the response is invalid, false otherwise.
   */
  get isResponseInvalid() {
    const { answers } = this.state;
    return answers.some(answer => isNil(answer));
  }

  /**
   * Getter for default state.
   *
   * @readonly
   * @returns { object } The default state.
   */
  get defaultState() {
    const { answersCount, options } = this.props;
    return { answers: Array(answersCount).fill(undefined), options };
  }

  /**
   * Handles the drag motion for draggable items.
   *
   * @param { object } result The result of drag motion.
   *
   */
  handleDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) {
      return;
    }

    const valueDragged = head(draggableId.split('-'));
    const { droppableId: destionationId } = destination;
    const { droppableId: sourceId, index: sourceIndex } = source;
    if (startsWith(destionationId, 'answers') && isEqual(sourceId, 'options')) {
      const answerIndex = last(destionationId.split('-'));
      const { answers } = this.state;
      const answerBoxValue = answers[answerIndex];
      if (isEmpty(answerBoxValue)) {
        this.setAnswer(valueDragged, sourceIndex, answerIndex);
      }
    } else if (startsWith(sourceId, 'answers') && isEqual(destionationId, 'options')) {
      this.setOption(valueDragged, sourceIndex);
    }
  };

  /**
   * Render the boxes for drag and drop.
   *
   * @param { object } props The props to render the box.
   *
   * @returns { Component } The box component to render.
   */
  renderBox = ({ children, className, handleOnClick }) => (
    <Paper className={className} elevation={1} onClick={handleOnClick}>
      <Typography variant="subtitle1">
        {children}
      </Typography>
    </Paper>
  );

  /**
   * Handles the go to next action.
   */
  handleGoToNext = () => {
    const { goToNext } = this.props;
    const { answers } = this.state;
    goToNext(answers);
  }

  /**
   * React Lifecycle method, render all components to display in APP
   *
   * @return { object } The object to render.
   */
  render() {
    const { labels, answersOrder, boxSize, classes, asColumn } = this.props;
    const { answers, options } = this.state;

    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <CustomLabel label={labels} variant="h6" asColumn={asColumn}>
          {
            answers.map((answer, answerIndex) => (
              <Droppable
                key={`response-box-${answerIndex.toString()}`}
                droppableId={`answers-${answerIndex}`}
                style={{ order: answersOrder ? answersOrder[answerIndex] : 9999 }}
              >
                {
                  isEmpty(answer)
                    ? (
                      <this.renderBox className={`answer ${classes[boxSize]}`}>
                        {answer}
                      </this.renderBox>
                    )
                    : (
                      <Draggable draggableId={`${answer}-${answerIndex}-answer`} index={answerIndex}>
                        <this.renderBox
                          className={`answer colored ${boxSize}`}
                          handleOnClick={() => this.setOption(answer, answerIndex)}
                        >
                          {answer}
                        </this.renderBox>
                      </Draggable>
                    )
                }
              </Droppable>
            ))
          }
        </CustomLabel>
        <div className={classes.options}>
          <Droppable droppableId="options" className={`container ${asColumn && classes.column}`}>
            {
              options.map((option, optionIndex) => (
                <Draggable
                  key={`options-button-${optionIndex.toString()}`}
                  draggableId={`${option}-${optionIndex}-option`}
                  index={optionIndex}
                >
                  <this.renderBox className="option" handleOnClick={() => this.setAnswer(option, optionIndex)}>
                    {option}
                  </this.renderBox>
                </Draggable>
              ))
            }
          </Droppable>
        </div>
        <GoToNext disabled={this.isResponseInvalid} onClick={this.handleGoToNext} />
        <div className={classes.img}><img className={classes.logo} src={isotype} alt="" /></div>
      </DragDropContext>
    );
  }
}

export default withStyles(styles)(LayoutDraggable);
