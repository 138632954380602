/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

/**
 * Css in js styles for {@link CustomLabel}
 *
 * @author Daniel Mejia
 * @file styles.js
 */

const styles = theme => ({
  breakLine: {
    width: '100% !important'
  },
  container: {
    marginTop: '1em',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    '& .label': {
      color: theme.palette.primary.questionTextColor,
      margin: '0.1em'
    },
    '& .answer': {
      backgroundColor: '#EBEBEB',
      padding: '0.5em 1.5em',
      minHeight: '2em',
      borderRadius: '0.7em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '1em',
      paddingLeft: '1em',
      margin: '0.25em'
    },
    '& .colored': {
      backgroundColor: theme.palette.primary.answerColor,
      '& > *': {
        color: 'white'
      }
    },
    '& > *': {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      flexDirection: 'row',
      maxWidth: '100%'
    }
  },
  column: {
    flexDirection: 'column !important'
  }
});

export default styles;
