/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import { defineMessages } from 'react-intl';

/**
 * i18n Text messages to components
 *
 * @author Daniel Mejia
 * @file message.js
 */

export default defineMessages({
  pageMessage: {
    id: 'cael.components.NotFoundPage.pageMessage',
    defaultMessage: '404 Not Found'
  }
});
