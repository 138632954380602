/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

// Redux-form
import { reducer as formReducer } from 'redux-form/immutable';

// App redux
import globalReducer from '../../containers/App/redux/appReducer';
import { STORE_NAME } from '../../containers/App/redux/constants';

// Language provider redux
import languageProviderReducer from '../../containers/LanguageProvider/reducer';

// Helper redux
import reduxHelperReducer from '../../common/reduxHelpers/reduxHelperReducer';
import { STORE_NAME as REDUX_HELPER_STORE_NAME } from '../../common/reduxHelpers/constants';

// Utils
import history from './utils/history';

/**
 * Combine all reducers in this file to app.
 *
 * @author React Boilerplate Team
 * @file reducers.js
 */

/**
 * Creates the main reducer with the dynamically injected ones
 *
 * @param { object } injectedReducers - Contain states and actions to execute in store
 */
export default function createReducers(injectedReducers = {}) {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    [REDUX_HELPER_STORE_NAME]: reduxHelperReducer,
    [STORE_NAME]: globalReducer,
    language: languageProviderReducer,
    form: formReducer,
    ...injectedReducers
  });

  return rootReducer;
}
