/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: 'Software Organizacional Fácil e Inteligente'.
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: 'Superintendencia de Industria y Comercio'.
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { PropTypes } from 'prop-types';

// Components
import LayoutDraggable from '../LayoutDraggable';

/**
 * Order sentences view component.
 *
 * @author Daniel Mejia.
 * @class OrderSentences
 */
class OrderSentences extends React.PureComponent {
  /**
   * Component propTypes checker.
   *
   * @type { Object }
   */
  static propTypes = {
    question: PropTypes.object,
    goToNext: PropTypes.func
  };

  /**
   * React Lifecycle method, render all components to display in APP
   *
   * @return { object } The object to render.
   */
  render() {
    const { multimedia, question: { sentences }, goToNext } = this.props;

    return (
      <div>
        {multimedia}
        <LayoutDraggable
          boxSize="large"
          answersCount={sentences.length}
          options={sentences}
          goToNext={goToNext}
          asColumn
        />
      </div>
    );
  }
}

export default OrderSentences;
