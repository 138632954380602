/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { RingLoader } from 'react-spinners';

// Styles
import './style.scss';
import { primaryColor } from '../../styles';
/**
 * Loading component.
 *
 * @author Daniel Mejia
 * @file index.jsx
 */

/**
 * Loading component definition.
 *
 * @param { object } The proptypes for the component.
 *
 * @return { object } The component to render.
 */
function Loading({ loading, className, size, color }) {
  return (
    <div className="container-loading">
      <RingLoader
        className={className}
        sizeUnit="px"
        size={size}
        color={color}
        loading={loading}
      />
    </div>
  );
}

/**
 * Validate protypes allowed by components
 */
Loading.prototype = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number
};

/**
 * Initialize default properties
 */
Loading.defaultProps = {
  loading: true,
  size: 50,
  color: primaryColor
};

export default Loading;
