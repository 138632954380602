/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: 'Software Organizacional Fácil e Inteligente'.
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: 'Superintendencia de Industria y Comercio'.
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import ProgressiveImage from 'react-progressive-image';

/**
 * Image Multimedia component for questions.
 *
 * @author Daniel Mejia.
 * @class ImageMultimedia
 */
function ImageMultimedia(props) {
  const { multimediaFile: { multimedia, author }, classes } = props;

  return (
    <ProgressiveImage src={multimedia}>
      {(src, loading) => (
        <img
          src={src}
          alt={src ? `created by ${author}` : 'Imagen no encontrada'}
          className={classes.multimediaImage}
        />
      )
      }
    </ProgressiveImage>
  );
}

export default ImageMultimedia;
