/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

/**
 * Styles for {@link Layout}
 *
 * @author Daniel Mejia
 * @file styles.js
 */

const styles = theme => ({
  root: {
    '& > *': {
      marginRight: '10%',
      marginLeft: '10%'
    }
  },
  header: {
    backgroundColor: 'white',
    textAlign: 'right'
  },
  title: {
    paddingTop: '1em',
    color: 'black',
    bottom: '0',
    verticalAlign: 'bottom',
    textAlign: 'left',
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.5em'
    }
  },
  logo: {
    position: 'absolute',
    top: 0,
    right: '10%',
    height: '9em',
    [theme.breakpoints.down('sm')]: {
      right: '-4em'
    }
  },
  pageContent: {
    marginTop: '1.5em',
    marginBottom: '4.5em'
  }
});

export default styles;
