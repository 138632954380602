/**
 * Copyright (C) 2009 Fundación Centro Cultural Colombo Americano de Cali.
 *
 * Este archivo es parte de SOFI: "Software Organizacional Fácil e Inteligente".
 *
 * Este programa es software comercial: está prohibida la venta, distribución y modificación de
 * SOFI sin la autorización de la Fundacion Centro Cultural Colombo Americano de Cali.
 *
 * Este programa fue registrado bajo las leyes de registro mercantil y ley de derechos de autor en
 * la SIC: "Superintendencia de Industria y Comercio".
 *
 * Cualquier duda o información acerca del programa se debe enviar a info@colomboamericano.edu.co.
 */

// Dependencies
import React from 'react';
import { PropTypes } from 'prop-types';

// Components
import Multimedia from '../Multimedia';
import SelectSingleAnswer from '../SelectSingleAnswer';
import CorrectOrderDraggable from '../CorrectOrderDraggable';
import SelectDraggable from '../SelectDraggable';
import Writing from '../Writing';
import CompleteDraggable from '../CompleteDraggable';
import OrderSentences from '../OrderSentences';

/**
 * Question types.
 */
const questionTypes = {
  selectSingleAnswer: SelectSingleAnswer,
  correctOrderDraggable: CorrectOrderDraggable,
  selectDraggable: SelectDraggable,
  pairingDraggable: SelectDraggable,
  writing: Writing,
  completeDraggable: CompleteDraggable,
  orderSentences: OrderSentences
};

/**
 * Stepper question component view.
 *
 * @author Daniel Mejia.
 * @class Stepper
 */
function Stepper(props) {
  const { assessmentQuestion, goToNext, children, previewView } = props;
  const {
    assessmentId,
    questionGroupId,
    questionTypeId,
    questionId,
    typeName,
    multimediaFile = {},
    question
  } = assessmentQuestion;
  const questionKey = `question-${assessmentId}-${questionGroupId}-${questionTypeId}-${questionId}`;
  const QuestionComponent = questionTypes[typeName];
  const multimedia = multimediaFile.type
    && (
      <Multimedia
        previewView={previewView}
        questionKey={questionKey}
        question={question}
        multimediaFile={multimediaFile}
      />
    );

  return (
    <div className="question-stepper-container">
      <QuestionComponent questionKey={questionKey} multimedia={multimedia} question={question} goToNext={goToNext} />
      {children}
    </div>
  );
}

/**
 * Multimedia proptypes.
 *
 * @type { object }
 */
Stepper.prototype = {
  assessmentQuestion: PropTypes.object.isRequired,
  goToNext: PropTypes.func.isRequired
};

export default Stepper;
